import React from "react";
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Col, Row } from "../components/GridSystem";
import Layout from "../components/Layout";
import {
  StyledFullWidthContainer,
  StyledGridImg,
} from "../components/GridImages/style";
import InsertMetaTags from "../components/InsertMetaTags";

export default function BlogPost({ data }) {
  const post = data.contentfulPost;
  const locale = data.site.siteMetadata.locale;

  return (
    <Layout pageTitle={post.title} showBackButton backUrl="/portifolio" locale={locale}>
      <InsertMetaTags title={post.title} />
      <article>
        <section>
          <Row>
            <Col sm={12} md={12} lg={12} style={{ paddingBottom: 0 }}>
              <StyledFullWidthContainer>
                <StyledGridImg
                  width={1200}
                  height={700}
                  src={`${post.mainImage.file.url}?w=1200&h=800&fit=thumb&fm=webp&q=80`}
                  alt={post.mainImage.title}
                />
              </StyledFullWidthContainer>
            </Col>
            <Col sm={12} md={12} lg={12} style={{ marginBottom: "15px" }}>
              {renderRichText(post.content)}
            </Col>
            {post.postImages?.map((image) => {
              const imageSrc = `${image.file.url}?w=800&h=700&fit=thumb&fm=webp&q=50`;

              return (
                <Col key={image.id} sm={12} md={6} lg={6}>
                  <StyledFullWidthContainer>
                    <StyledGridImg
                      src={imageSrc}
                      width={800}
                      height={600}
                      alt={image.title}
                    />
                  </StyledFullWidthContainer>
                </Col>
              );
            })}
          </Row>
        </section>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!, $locale: String!) {
    contentfulPost(node_locale: { eq: $locale } slug: { eq: $slug }) {
      id
      slug
      title
      content {
        raw
      }
      mainImage {
        id
        title
        file {
          url
        }
      }
      postImages {
        id
        title
        file {
          url
        }
      }
    }
    site { siteMetadata { locale } }
  }
`;
